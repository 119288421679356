import getValue from './getValue';
import sha256 from './sha256';
import log from './logger';

const addUserIds = dmps => {
  if (!dmps) dmps = [];
  let parsedId = {};
  let parsedIds = [];

  const clean = str => {
    // @see https://docs.piano.io/event-data/
    const len = 64;

    str = str.replace(/[^A-Za-z0-9 =@+\-_.|]/g, '');
    str = str.substring(0, len);

    return str;
  };

  if (dmps.length) {
    dmps.forEach(dmp => {
      dmp.ids.forEach(id => {
        parsedId = getValue(id);
        if (parsedId.value) {
          parsedId = {
            ...parsedId,
            value: id.hash ? sha256(parsedId.value) : clean(parsedId.value),
            prefix: id.prefix ? id.prefix : dmp.prefix,
          };
          parsedIds.push(parsedId);
        }
      });
      dmp.parsedIds = parsedIds;
      parsedIds = [];
    });
  } else {
    log('Warning: no DMP defined, configuring empty one');
  }

  return dmps;
};
export default addUserIds;
