import Storage from './Storage';
import log from './logger';
import sha256 from './sha256';

// syncronous function because we should be called from 'invoke' callqueue
const getSegments = dmps => {
  dmps.forEach(dmp => {
    let identities = [];

    dmp.parsedIds.forEach(pid => {
      identities.push({
        id: pid.hash ? sha256(pid.value) : pid.value,
        type: pid.prefix,
      });
    });

    dmp.segments.forEach(segment => {
      window.cX.getUserSegmentIds(
        {
          persistedQueryId: segment.queryid,
          callback: userSegmentIds => {
            Storage.set(
              segment.name,
              JSON.stringify(userSegmentIds),
              segment.type
            );
          },
        },
        identities.length ? { identities } : {}
      );
      log(`stored ${segment.name} with identity ${JSON.stringify(identities)}`);
      identities = [];
    });
  });
};

export default getSegments;
