import log from './logger';

const addExternalIds = dmps => {
  dmps.forEach(dmp => {
    // Check and assign default values if necessary
    if (!dmp.prefix) {
      log('ERROR: dmp.prefix is not defined'); // TODO make actual console log error
    }

    if (dmp.parsedIds) {
      dmp.parsedIds.forEach(pid => {
        window.cX.callQueue.push([
          'addExternalId',
          { id: pid.value, type: pid.prefix },
        ]);
        log(`add externalId ${pid.value} on ${pid.prefix}`);
      });
    }
  });
};

export default addExternalIds;
