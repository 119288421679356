import log from './logger';

const asyncAddUserId = dmp => {
  let newDmp;
  if (
    dmp &&
    dmp.length &&
    dmp[0].ids &&
    dmp[0].ids[0] &&
    dmp[0].ids[0].type &&
    dmp[0].ids[0].type === 'auth0'
  ) {
    try {
      const auth0SdkId = window.__NEXT_DATA__.runtimeConfig.AUTH0_CLIENT_ID;
      newDmp = window.CIAM.getUserInfo(auth0SdkId)
        .then(result => {
          dmp[0].parsedIds.push({ key: 'auth0', value: result.sub });
          return dmp;
        })
        .catch(error => {
          log('Auth0-SDK Error: ', error);
        });
    } catch (error) {
      log(error);
    }
  } else {
    newDmp = Promise.resolve(dmp);
  }
  return newDmp;
};
export default asyncAddUserId;
